import styled from "styled-components"

const BaseButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
`

export const HeaderButton = styled(BaseButton)`
  height: 60px;
  padding: 0px 40px;
  margin: 0 auto;
`

export const SubmitButton = styled(BaseButton)`
  height: 50px;
  padding: 0px 30px;
`
