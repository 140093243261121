import React from "react"
import { Section, Container } from "../global"
import { Formik, Form, Field, ErrorMessage } from "formik"
import styled, { css } from "styled-components"
import { SubmitButton } from "../common/button"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function Contact() {
  return (
    <Section id="contact">
      <Container>
        <h1>Contact</h1>

        <Formik
          initialValues={{ email: "", text: "" }}
          validate={values => {
            const errors = {}
            if (!values.email) {
              errors.email = "Email is required"
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address"
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: encode({
                "form-name": "dring-contact",
                email: values.email,
                text: values.text,
              }),
            })
              .then(() => alert("Your message was succesfully sent!"))
              .catch(error => alert(error))

            setSubmitting(false)
          }}
        >
          {({ isSubmitting }) => (
            <StyledForm name="dring-contact" method="POST" data-netlify="true">
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="dring-contact" />
              <div className="input-container">
                <StyledInput placeholder="Email" type="email" name="email" />
                <ErrorMessage name="email" component="div" />
              </div>
              <StyledTextArea
                placeholder="Message"
                component="textarea"
                type="text"
                name="text"
              />
              <SubmitButton type="submit" disabled={isSubmitting}>
                Submit
              </SubmitButton>
            </StyledForm>
          )}
        </Formik>
      </Container>
    </Section>
  )
}

const StyledForm = styled(Form)`
  /* display: flex;
  flex-direction: row; */
  /* padding-bottom: 16px; */

  /* @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  } */

  .input-container {
    height: 92px;
  }
`
const FieldStyles = css`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.white.dark};
  background-color: ${props => props.theme.color.black.regular};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  margin-bottom: 8px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const StyledInput = styled(Field)`
  ${FieldStyles}
`

const StyledTextArea = styled(Field)`
  ${FieldStyles}
  height: 250px;
`

export default Contact
