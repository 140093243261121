export const SPLINE_EXPORTED_SCENE = {
  scenes: [{ nodes: [0] }],
  nodes: [
    { children: [1, 2, 3, 4, 5, 6] },
    {
      mesh_spe: 0,
      material: 0,
      spe_interaction: 0,
      translation: [124.88909511100746, 40.08805435346764, 0.48041644901306313],
      rotation: [0, 0, 0, 1],
      scale: [1, 1, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [
        0.6410635155096013,
        0,
        0,
        0,
        0,
        0.6646942800788955,
        0,
        0,
        0,
        0,
        0.6189258312020461,
        0,
        -64.00280336681985,
        40.59486609460611,
        -110.22944498750368,
        1,
      ],
      type: "text",
      uuid: "098FD108-34FC-4793-8FC3-3173E6557013",
      spe_cloner_data: {},
      children: [
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
        53,
      ],
    },
    {
      spe_empty_object: true,
      spe_interaction: 1,
      translation: [107.84696913502786, -202.3850229698868, -6.388879651614417],
      rotation: [
        -0.059391174613884636,
        0.9254165783983235,
        -0.3368240888334651,
        0.16317591116653482,
      ],
      scale: [0.5312102657947828, 0.4902547654611347, 0.46906745190653515],
      hiddenMatrix: [
        0.6410635155096013,
        0,
        0,
        0,
        0,
        0.6646942800788955,
        0,
        0,
        0,
        0,
        0.6189258312020461,
        0,
        -64.00280336681985,
        40.59486609460611,
        -110.22944498750368,
        1,
      ],
      visible: true,
      uuid: "A464A30B-49C3-4ECC-B9D4-41DAF74FF2EA",
      children: [54, 55, 56, 57, 58, 59, 60, 61, 62, 63],
    },
    {
      spe_empty_object: true,
      spe_interaction: 2,
      translation: [337.34295328320167, 96.58243515423808, -215.16058805616606],
      rotation: [
        7.499399432609232e-17,
        0.9659258262890683,
        7.499399432609232e-17,
        0.2588190451025207,
      ],
      scale: [0.5312102657947828, 0.4902547654611347, 0.4690674519065352],
      hiddenMatrix: [
        0.6410635155096013,
        0,
        0,
        0,
        0,
        0.6646942800788955,
        0,
        0,
        0,
        0,
        0.6189258312020461,
        0,
        -64.00280336681985,
        40.59486609460611,
        -110.22944498750368,
        1,
      ],
      visible: true,
      uuid: "386F4F02-1C81-41DD-8F4B-CA776AED211F",
      children: [64, 65, 66, 67, 68, 69, 70, 71, 72, 73],
    },
    {
      spe_empty_object: true,
      spe_interaction: 3,
      translation: [
        -137.66600453049634,
        96.58243515423806,
        -212.57955019134621,
      ],
      rotation: [
        4.329780281177466e-17,
        0.9659258262890683,
        4.329780281177466e-17,
        -0.2588190451025207,
      ],
      scale: [0.5312102657947828, 0.4902547654611347, 0.4690674519065352],
      hiddenMatrix: [
        0.6410635155096013,
        0,
        0,
        0,
        0,
        0.6646942800788955,
        0,
        0,
        0,
        0,
        0.6189258312020461,
        0,
        -64.00280336681985,
        40.59486609460611,
        -110.22944498750368,
        1,
      ],
      visible: true,
      uuid: "C25C4AC7-1749-47C2-8F0A-AD22DFC3000F",
      children: [74, 75, 76, 77, 78, 79, 80, 81, 82, 83],
    },
    {
      spe_interaction: 4,
      camera: 0,
      translation: [9.051438450844749e-54, 6.123233995736766e-14, 1000],
      rotation: [
        -3.061616997868383e-17,
        4.525719225422374e-57,
        1.3856018908132872e-73,
        1,
      ],
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      visible: true,
      uuid: "EFE32D08-763A-4DE2-B668-F0D137A7D6AF",
      children: [84],
    },
    { visible: true, extensions: { KHR_lights_punctual: { light: 0 } } },
    {
      mesh_spe: 1,
      material: 1,
      spe_interaction: null,
      translation: [-111.5, 74.925, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "62B6427A-A4A9-4C98-A2C0-C3F3305E9173",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 2,
      material: 2,
      spe_interaction: null,
      translation: [-94.544, 74.925, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "D9A65070-AC16-410F-A3C4-070EC89576E5",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 3,
      material: 3,
      spe_interaction: null,
      translation: [-75.86, 74.925, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "1FD0D27A-2D16-4B88-9165-3E7A688860C6",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 4,
      material: 4,
      spe_interaction: null,
      translation: [-58.796, 74.925, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "B99C1333-D1B6-4CC9-94E3-FE500373D318",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 5,
      material: 5,
      spe_interaction: null,
      translation: [-51.29, 74.925, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "BD37397E-5C87-4F89-A742-26E3036F3EE8",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 6,
      material: 6,
      spe_interaction: null,
      translation: [-30.662000000000006, 74.925, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "C6B53152-9A9D-4545-9A65-83BAB9B2A4A2",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 7,
      material: 7,
      spe_interaction: null,
      translation: [-11.573000000000008, 74.925, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "C5D04F71-2E10-4C9D-BE0F-F113007C598E",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 8,
      material: 8,
      spe_interaction: null,
      translation: [-3.986000000000004, 74.925, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "2326F348-E4A8-4CB2-B76C-9FC6B7AB3BAF",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 9,
      material: 9,
      spe_interaction: null,
      translation: [16.912000000000006, 74.925, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "4CB27BFA-77EE-4FAA-AA96-EB25A5C5805D",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 10,
      material: 10,
      spe_interaction: null,
      translation: [36.837999999999994, 74.925, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "1D585F83-67F9-47DE-8CEC-2D1BF0217B3D",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 11,
      material: 11,
      spe_interaction: null,
      translation: [-111.5, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "1C55E280-5C5B-461C-8C05-3B3AE51153D5",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 12,
      material: 12,
      spe_interaction: null,
      translation: [-94.544, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "2CAFDA12-937E-493F-B35A-DE771574F043",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 13,
      material: 13,
      spe_interaction: null,
      translation: [-74.99600000000001, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "DCDF201F-743A-4797-A030-82D8212766DF",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 14,
      material: 14,
      spe_interaction: null,
      translation: [-55.232, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "EC43AB1E-DDEF-4F33-98AC-2C21E556D661",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 15,
      material: 15,
      spe_interaction: null,
      translation: [-37.196, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "E9A45DD9-D748-452D-9EC2-2BFB06D5D855",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 16,
      material: 16,
      spe_interaction: null,
      translation: [-29.689999999999998, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "65A56590-944B-4D79-80DA-EDC9ECDBE946",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 17,
      material: 17,
      spe_interaction: null,
      translation: [-9.061999999999998, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "0499E785-770C-4191-993E-EC79FF9D3D17",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 18,
      material: 18,
      spe_interaction: null,
      translation: [9.622, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "6DAF22E3-1485-40ED-8E68-6E0906A10E67",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 19,
      material: 19,
      spe_interaction: null,
      translation: [29.089, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "F56E3BFC-4E30-4A0E-88EB-2A168EC0315F",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 20,
      material: 20,
      spe_interaction: null,
      translation: [46.04499999999999, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "BCD45DD1-30C8-47F0-8EA3-3786D765319D",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 21,
      material: 21,
      spe_interaction: null,
      translation: [66.40299999999999, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "CFAF1312-CF1B-4E66-8F92-7713BE46AE2A",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 22,
      material: 22,
      spe_interaction: null,
      translation: [85.654, 24.974999999999994, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "770A0D03-6C47-425B-BD1E-C106B94D4381",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 23,
      material: 23,
      spe_interaction: null,
      translation: [-111.5, -24.975000000000023, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "BCAE2936-CF52-4F73-AF18-1612AD1BDBB6",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 24,
      material: 24,
      spe_interaction: null,
      translation: [-92.411, -24.975000000000023, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "E6944419-88B2-4EA6-9FD8-02B0D8C73CFA",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 25,
      material: 25,
      spe_interaction: null,
      translation: [-72.053, -24.975000000000023, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "A7AE6CDD-F6A2-4F74-AAF2-8ABC7CDB9476",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 26,
      material: 26,
      spe_interaction: null,
      translation: [-51.452, -24.975000000000023, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "C363CCA8-BEAC-4C50-8B81-A71D6C4AAE7B",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 27,
      material: 27,
      spe_interaction: null,
      translation: [-32.363, -24.975000000000023, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "4838FDBD-C793-4FAC-9BFF-7FFF05CFDDD0",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 28,
      material: 28,
      spe_interaction: null,
      translation: [-24.857, -24.975000000000023, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "B105ECED-C5AB-4E5C-9542-9CDADB48A9E8",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 29,
      material: 29,
      spe_interaction: null,
      translation: [2.844999999999999, -24.975000000000023, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "B76059D7-7CB9-4A91-9337-81EC056328FE",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 30,
      material: 30,
      spe_interaction: null,
      translation: [21.528999999999996, -24.975000000000023, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "7E210093-5A2B-4ED5-9545-59CDDFDEE577",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 31,
      material: 31,
      spe_interaction: null,
      translation: [41.293000000000006, -24.975000000000023, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "EB3F831F-3B52-4174-BC6D-C3D5811E2CE0",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 32,
      material: 32,
      spe_interaction: null,
      translation: [56.116000000000014, -24.975000000000023, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "17DADA6A-61EB-4D3C-A270-7747CDD7469D",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 33,
      material: 33,
      spe_interaction: null,
      translation: [-111.5, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "0A6D5D54-BF1D-4D14-8705-C6A46FA88987",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 34,
      material: 34,
      spe_interaction: null,
      translation: [-91.952, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "A639BE1C-F3FC-4ED1-80A7-480D2AA606C9",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 35,
      material: 35,
      spe_interaction: null,
      translation: [-71.054, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "E63339EE-F191-4472-BBE6-28C26AAA2285",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 36,
      material: 36,
      spe_interaction: null,
      translation: [-50.426, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "C81B1449-3103-4F37-99B4-9952610B8BD4",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 37,
      material: 37,
      spe_interaction: null,
      translation: [-42.92, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "2DFB40BF-37A7-4A65-AE29-622D401C9A68",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 38,
      material: 38,
      spe_interaction: null,
      translation: [-18.430999999999997, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "7C29FC3F-3D43-4D09-BA73-18EBB8C036BE",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 39,
      material: 39,
      spe_interaction: null,
      translation: [1.9270000000000067, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "3639E751-7556-4CD1-8E7B-3407B04D940E",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 40,
      material: 40,
      spe_interaction: null,
      translation: [21.691000000000003, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "D23DED25-E190-494C-ACB9-A3DF64E92421",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 41,
      material: 41,
      spe_interaction: null,
      translation: [29.27799999999999, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "67904126-5F1A-4417-9D93-7C9E82C33C48",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 42,
      material: 42,
      spe_interaction: null,
      translation: [46.23399999999998, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "2E53A94E-B784-4DF9-B010-4CCF30CF5CAE",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 43,
      material: 43,
      spe_interaction: null,
      translation: [64.91799999999998, -74.92500000000001, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "853FB93C-1A15-4873-87DD-83AB42FD9F79",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 44,
      material: 44,
      spe_interaction: null,
      translation: [-111.5, -124.875, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "03B637EF-99A6-4A54-AB18-0DCDA9B5714C",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 45,
      material: 45,
      spe_interaction: null,
      translation: [-91.952, -124.875, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "10B60B1F-4F7D-49FB-985A-93884781F9D7",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 46,
      material: 46,
      spe_interaction: null,
      translation: [-72.701, -124.875, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "B2E3F03B-5E33-4203-8DDA-EE3F58E6B210",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 47,
      material: 47,
      spe_interaction: null,
      translation: [-53.45, -124.875, 0],
      rotation: [0, 0, 0, 1],
      scale: [27, 27, 1],
      visible: true,
      castShadow: false,
      receiveShadow: false,
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      type: "character",
      uuid: "B092F094-3006-4226-88BE-6CD65E99D55F",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 48,
      material: 48,
      spe_interaction: 5,
      translation: [-25.86230841963186, -85.75431170642273, 51.53162321895245],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "5FF6B261-39DA-4577-9F9D-36E603A73110",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 49,
      material: 49,
      spe_interaction: 6,
      translation: [
        -111.73070903300784,
        -13.337993590401727,
        49.96114586733991,
      ],
      rotation: [
        0.008726203218641758,
        -0.00872620321864172,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999998],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "3A552A25-55BE-47F1-8F3F-153C9D78CA83",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 50,
      material: 50,
      spe_interaction: 7,
      translation: [49.86319490482285, -13.318956934748499, 50.17926772173054],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "7736728E-910D-4A8B-9FC0-B16AF4FB991B",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 51,
      material: 51,
      spe_interaction: 8,
      translation: [-116.79542130962759, 63.898475103964785, 55.06093945452554],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "F452059F-7D98-4D09-9A22-CFFB67DEFBC6",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 52,
      material: 52,
      spe_interaction: 9,
      translation: [51.625887954988585, 63.898475103964785, 50.17926772173054],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "D372239B-2659-436D-9607-311D230368E2",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 53,
      material: 53,
      spe_interaction: 10,
      translation: [26.629695576078788, 133.50409834719503, 48.69597930493849],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "4A0422AA-BC7A-48A9-A974-DC57212EF2EF",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 54,
      material: 54,
      spe_interaction: 11,
      translation: [0, 225, 20],
      rotation: [
        0.00607684726295088,
        0.7081513688571189,
        -0.00607684726295088,
        0.7060083445969136,
      ],
      scale: [1, 0.9999999999999998, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "B11CDA30-8C07-4827-9774-165CD0542313",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 55,
      material: 55,
      spe_interaction: 12,
      translation: [50, 225, 20],
      rotation: [
        0.00607684726295088,
        0.7081513688571189,
        -0.00607684726295088,
        0.7060083445969136,
      ],
      scale: [1, 0.9999999999999998, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "19105674-BF68-4CF6-B119-0F1A3B3AFBF2",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 56,
      material: 56,
      spe_interaction: 13,
      translation: [100, 225, 20],
      rotation: [
        0.00607684726295088,
        0.7081513688571189,
        -0.00607684726295088,
        0.7060083445969136,
      ],
      scale: [1, 0.9999999999999998, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "AB59A83D-57B3-4F1A-9A6F-8CCAED0E06A7",
      spe_cloner_data: {},
    },
    {
      spe_empty_object: true,
      spe_interaction: 14,
      translation: [-53.615572094458365, 64.38810988363635, 67.69981077609822],
      rotation: [0, 0, 0, 1],
      scale: [1.010581333333332, 1.0450892665811868, 0.9659958690107827],
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      visible: true,
      uuid: "8F8202F1-200B-43C8-8641-24092C2F2257",
      children: [85, 86],
    },
    {
      mesh_spe: 57,
      material: 57,
      spe_interaction: 15,
      translation: [-25.86230841963186, -85.75431170642273, 51.53162321895245],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "F4EC750A-D531-42F2-BE9E-94E7BD43B4CA",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 58,
      material: 58,
      spe_interaction: 16,
      translation: [
        -111.73070903300784,
        -13.337993590401727,
        49.96114586733991,
      ],
      rotation: [
        0.008726203218641758,
        -0.00872620321864172,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999998],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "60D0FFE5-BDB2-4D7D-8AE4-87BD82EB5D01",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 59,
      material: 59,
      spe_interaction: 17,
      translation: [49.86319490482285, -13.318956934748499, 50.17926772173054],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "8BB81D78-8345-461C-932A-DE2D164B04BA",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 60,
      material: 60,
      spe_interaction: 18,
      translation: [-116.79542130962759, 63.898475103964785, 55.06093945452554],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "0B1EB067-D829-4FCF-81CF-E029F254379F",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 61,
      material: 61,
      spe_interaction: 19,
      translation: [51.625887954988585, 63.898475103964785, 50.17926772173054],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "429E475C-BE69-49CF-922C-D9FA65D9DC86",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 62,
      material: 62,
      spe_interaction: 20,
      translation: [26.629695576078788, 133.50409834719503, 48.69597930493849],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "8B7B328E-9517-4663-A9F2-DEB9AC5CAF8C",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 63,
      material: 63,
      spe_interaction: 21,
      translation: [0, 225, 20],
      rotation: [
        0.00607684726295088,
        0.7081513688571189,
        -0.00607684726295088,
        0.7060083445969136,
      ],
      scale: [1, 0.9999999999999998, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "55B052A5-774F-4379-984E-B5D0534C3C80",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 64,
      material: 64,
      spe_interaction: 22,
      translation: [50, 225, 20],
      rotation: [
        0.00607684726295088,
        0.7081513688571189,
        -0.00607684726295088,
        0.7060083445969136,
      ],
      scale: [1, 0.9999999999999998, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "98E6DE93-9E5E-485D-8DB5-C1689EAFFBFA",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 65,
      material: 65,
      spe_interaction: 23,
      translation: [100, 225, 20],
      rotation: [
        0.00607684726295088,
        0.7081513688571189,
        -0.00607684726295088,
        0.7060083445969136,
      ],
      scale: [1, 0.9999999999999998, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "414C2224-A66B-4F65-8376-1600150997EE",
      spe_cloner_data: {},
    },
    {
      spe_empty_object: true,
      spe_interaction: 24,
      translation: [-53.615572094458365, 64.38810988363635, 67.69981077609822],
      rotation: [0, 0, 0, 1],
      scale: [1.010581333333332, 1.0450892665811868, 0.9659958690107827],
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      visible: true,
      uuid: "2D2EA719-65B9-432B-81B4-E053B8C9D9A4",
      children: [87, 88],
    },
    {
      mesh_spe: 66,
      material: 66,
      spe_interaction: 25,
      translation: [-25.86230841963186, -85.75431170642273, 51.53162321895245],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "6635A57F-31C5-4CCB-A4DA-958662ACB97C",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 67,
      material: 67,
      spe_interaction: 26,
      translation: [
        -111.73070903300784,
        -13.337993590401727,
        49.96114586733991,
      ],
      rotation: [
        0.008726203218641758,
        -0.00872620321864172,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999998],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "B5814180-3F3C-4BBD-8502-CF5E4C43F945",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 68,
      material: 68,
      spe_interaction: 27,
      translation: [49.86319490482285, -13.318956934748499, 50.17926772173054],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "6EB12B5E-15A6-4DA7-9D51-123720965180",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 69,
      material: 69,
      spe_interaction: 28,
      translation: [-116.79542130962759, 63.898475103964785, 55.06093945452554],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "2D4EF834-0B74-4877-9910-4E4EFB060444",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 70,
      material: 70,
      spe_interaction: 29,
      translation: [51.625887954988585, 63.898475103964785, 50.17926772173054],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "FBBCE673-D88F-4C09-918A-6252787397E7",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 71,
      material: 71,
      spe_interaction: 30,
      translation: [26.629695576078788, 133.50409834719503, 48.69597930493849],
      rotation: [
        0.008726203218641758,
        -0.008726203218641718,
        0.9999238475781956,
        -0.00007615242180431886,
      ],
      scale: [0.9999999999999999, 1, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "A9A3B61E-C183-47E6-8C8A-38BE45174FFB",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 72,
      material: 72,
      spe_interaction: 31,
      translation: [0, 225, 20],
      rotation: [
        0.00607684726295088,
        0.7081513688571189,
        -0.00607684726295088,
        0.7060083445969136,
      ],
      scale: [1, 0.9999999999999998, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "AEBB6273-BF85-425C-A188-055F7E94303C",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 73,
      material: 73,
      spe_interaction: 32,
      translation: [50, 225, 20],
      rotation: [
        0.00607684726295088,
        0.7081513688571189,
        -0.00607684726295088,
        0.7060083445969136,
      ],
      scale: [1, 0.9999999999999998, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "36527EDC-7385-4FF5-B5F8-9453EC3B9F50",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 74,
      material: 74,
      spe_interaction: 33,
      translation: [100, 225, 20],
      rotation: [
        0.00607684726295088,
        0.7081513688571189,
        -0.00607684726295088,
        0.7060083445969136,
      ],
      scale: [1, 0.9999999999999998, 0.9999999999999999],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      type: "mesh3d",
      uuid: "B8D125F3-8724-41D0-BF01-189E5222E7C9",
      spe_cloner_data: {},
    },
    {
      spe_empty_object: true,
      spe_interaction: 34,
      translation: [-53.615572094458365, 64.38810988363635, 67.69981077609822],
      rotation: [0, 0, 0, 1],
      scale: [1.010581333333332, 1.0450892665811868, 0.9659958690107827],
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        53.42469960698412,
        -63.89847510396477,
        -55.06093945452555,
        1,
      ],
      visible: true,
      uuid: "FE0D83A3-65D7-4A60-A74D-E6103433A8EA",
      children: [89, 90],
    },
    {
      spe_interaction: null,
      translation: [850000, 1300000, 1000000],
      rotation: [0, 0, 0.5, 0],
      hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      uuid: "D0F8EDFD-E23A-4526-9B54-43D633CA3EEA",
      visible: true,
      extensions: { KHR_lights_punctual: { light: 1 } },
    },
    {
      mesh_spe: 75,
      material: 75,
      spe_interaction: 35,
      translation: [-78.78607189060418, 150.00000000000142, 129.7068285505219],
      rotation: [0, 0, 0, 1],
      scale: [1, 1, 1],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        78.97494583986358,
        -0.7955091150289633,
        -131.95275338759288,
        1,
      ],
      type: "mesh3d",
      uuid: "B5355FE2-9C49-4AB0-B2DC-6A0B1C637C35",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 76,
      material: 76,
      spe_interaction: 36,
      translation: [-49.44069960698462, 18.11297998586457, 77.39132177533611],
      rotation: [0, 0, 0, 1],
      scale: [1, 1, 1],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        49.62957355624354,
        -55.75449088497105,
        -79.63724661240713,
        1,
      ],
      type: "mesh3d",
      uuid: "FF58DD62-27E3-4FB0-ADB5-510476ECC68F",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 77,
      material: 77,
      spe_interaction: 37,
      translation: [-78.78607189060418, 150.00000000000142, 129.7068285505219],
      rotation: [0, 0, 0, 1],
      scale: [1, 1, 1],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        78.97494583986358,
        -0.7955091150289633,
        -131.95275338759288,
        1,
      ],
      type: "mesh3d",
      uuid: "91D304E3-97AA-424E-858C-A98E0F35C647",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 78,
      material: 78,
      spe_interaction: 38,
      translation: [-49.44069960698462, 18.11297998586457, 77.39132177533611],
      rotation: [0, 0, 0, 1],
      scale: [1, 1, 1],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        49.62957355624354,
        -55.75449088497105,
        -79.63724661240713,
        1,
      ],
      type: "mesh3d",
      uuid: "D43B341D-AB6D-4E4A-8030-CC37CBD94B98",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 79,
      material: 79,
      spe_interaction: 39,
      translation: [-78.78607189060418, 150.00000000000142, 129.7068285505219],
      rotation: [0, 0, 0, 1],
      scale: [1, 1, 1],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        78.97494583986358,
        -0.7955091150289633,
        -131.95275338759288,
        1,
      ],
      type: "mesh3d",
      uuid: "E3D96680-E9CD-4F21-8EE2-9A8640658307",
      spe_cloner_data: {},
    },
    {
      mesh_spe: 80,
      material: 80,
      spe_interaction: 40,
      translation: [-49.44069960698462, 18.11297998586457, 77.39132177533611],
      rotation: [0, 0, 0, 1],
      scale: [1, 1, 1],
      visible: true,
      castShadow: true,
      receiveShadow: true,
      hiddenMatrix: [
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        0,
        0,
        1,
        0,
        49.62957355624354,
        -55.75449088497105,
        -79.63724661240713,
        1,
      ],
      type: "mesh3d",
      uuid: "FBCDC4BD-E42F-46CF-99D6-857E1AD4BBBC",
      spe_cloner_data: {},
    },
  ],
  meshes: [],
  meshes_spe: [
    {
      type: "TextFrameGeometry",
      parameters: { width: 223, height: 239, depth: 0 },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "L",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 140.778, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "E",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 157.73399999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "T",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 35.64, y: 44.575 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: " ",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 176.41799999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "D",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 40.446, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "R",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 60.048, y: 144.47500000000002 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "I",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 133.191, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "N",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 19.548, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "G",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 128.412, y: 44.575 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: " ",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 176.41799999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "L",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 140.778, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "A",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 0, y: 244.375 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "B",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 113.427, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "S",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 58.05, y: 244.375 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: " ",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 176.41799999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "D",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 40.446, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "E",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 157.73399999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "V",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 121.122, y: 94.525 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "L",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 140.778, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "O",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 93.069, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "P",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 38.799, y: 244.375 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: " ",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 176.41799999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "Y",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 0, y: 144.47500000000002 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "O",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 93.069, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "U",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 39.447, y: 144.47500000000002 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "R",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 60.048, y: 144.47500000000002 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: " ",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 176.41799999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "W",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 86.643, y: 144.47500000000002 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "E",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 157.73399999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "B",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 113.427, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "-",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 152.793, y: 144.47500000000002 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: " ",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 176.41799999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "A",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 0, y: 244.375 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "N",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 19.548, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "D",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 40.446, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: " ",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 176.41799999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "M",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 68.58, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "O",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 93.069, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "B",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 113.427, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "I",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 133.191, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "L",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 140.778, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "E",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 157.73399999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: " ",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 176.41799999999998, y: 194.425 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "A",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 0, y: 244.375 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "P",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 38.799, y: 244.375 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "P",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 38.799, y: 244.375 },
      },
    },
    {
      type: "CharacterGeometry",
      parameters: {
        char: "S",
        fontFamily: "dosis_extralight",
        letterSpacing: 1,
        fontSize: 27,
        lod: 16,
        resolution: 1000,
        localPosition: { x: 58.05, y: 244.375 },
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 280,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 100,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 125,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 175,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 125,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 175,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "SphereGeometry",
      parameters: {
        width: 30,
        height: 30,
        depth: 30,
        widthSegments: 64,
        heightSegments: 64,
      },
    },
    {
      type: "SphereGeometry",
      parameters: {
        width: 30,
        height: 30,
        depth: 30,
        widthSegments: 64,
        heightSegments: 64,
      },
    },
    {
      type: "SphereGeometry",
      parameters: {
        width: 30,
        height: 30,
        depth: 30,
        widthSegments: 64,
        heightSegments: 64,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 280,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 100,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 125,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 175,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 125,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 175,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "SphereGeometry",
      parameters: {
        width: 30,
        height: 30,
        depth: 30,
        widthSegments: 64,
        heightSegments: 64,
      },
    },
    {
      type: "SphereGeometry",
      parameters: {
        width: 30,
        height: 30,
        depth: 30,
        widthSegments: 64,
        heightSegments: 64,
      },
    },
    {
      type: "SphereGeometry",
      parameters: {
        width: 30,
        height: 30,
        depth: 30,
        widthSegments: 64,
        heightSegments: 64,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 280,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 100,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 125,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 175,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 125,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 175,
        height: 45,
        depth: 101,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 128,
        cornerSegments: 8,
      },
    },
    {
      type: "SphereGeometry",
      parameters: {
        width: 30,
        height: 30,
        depth: 30,
        widthSegments: 64,
        heightSegments: 64,
      },
    },
    {
      type: "SphereGeometry",
      parameters: {
        width: 30,
        height: 30,
        depth: 30,
        widthSegments: 64,
        heightSegments: 64,
      },
    },
    {
      type: "SphereGeometry",
      parameters: {
        width: 30,
        height: 30,
        depth: 30,
        widthSegments: 64,
        heightSegments: 64,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 375,
        height: 75,
        depth: 100,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 5,
        cornerSegments: 1,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 375,
        height: 300,
        depth: 100,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 5,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 375,
        height: 75,
        depth: 100,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 5,
        cornerSegments: 1,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 375,
        height: 300,
        depth: 100,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 5,
        cornerSegments: 8,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 375,
        height: 75,
        depth: 100,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 5,
        cornerSegments: 1,
      },
    },
    {
      type: "CubeGeometry",
      parameters: {
        width: 375,
        height: 300,
        depth: 100,
        widthSegments: 1,
        heightSegments: 1,
        depthSegments: 1,
        cornerRadius: 5,
        cornerSegments: 8,
      },
    },
  ],
  materials: [
    {
      material_0_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: { ambient: [1, 1, 1], transparent: true, transparency: 1 },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: false,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16777215, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_1_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_2_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_3_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_4_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_5_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_6_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_7_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_8_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_9_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_10_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_11_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_12_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_13_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_14_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_15_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_16_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_17_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_18_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_19_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_20_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_21_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_22_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_23_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_24_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_25_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_26_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_27_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_28_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_29_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_30_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_31_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_32_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_33_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_34_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_35_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_36_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_37_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_38_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_39_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_40_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_41_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_42_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_43_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_44_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_45_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_46_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_47_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "CONSTANT",
            values: {
              ambient: [
                0.9647058823529412,
                0.6196078431372549,
                0.4823529411764706,
              ],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 2,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_48_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_49_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_50_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_51_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_52_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_53_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_54_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 13490148, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_55_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 13490148, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_56_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16768720, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_57_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_58_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_59_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_60_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_61_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_62_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_63_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 13490148, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_64_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 13490148, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_65_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16768720, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_66_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_67_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16162427, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_68_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_69_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_70_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_71_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 3685974, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_72_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 13490148, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_73_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 13490148, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_74_Basic: {
        extensions: {
          KHR_materials_common: {
            technique: "PHYSICAL",
            values: {
              transparent: true,
              transparency: 1,
              roughness: 0.3,
              metalness: 0,
              reflectivity: 0.5,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 16768720, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_75_Phong: {
        extensions: {
          KHR_materials_common: {
            technique: "PHONG",
            values: {
              ambient: [
                0.34901960784313724,
                0.34901960784313724,
                0.34901960784313724,
              ],
              diffuse: [
                0.34901960784313724,
                0.34901960784313724,
                0.34901960784313724,
              ],
              specular: [
                0.06666666666666667,
                0.06666666666666667,
                0.06666666666666667,
              ],
              shininess: 30,
              emission: [0, 0, 0],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 11186877, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_76_Phong: {
        extensions: {
          KHR_materials_common: {
            technique: "PHONG",
            values: {
              ambient: [
                0.396078431372549,
                0.42745098039215684,
                0.47058823529411764,
              ],
              diffuse: [
                0.396078431372549,
                0.42745098039215684,
                0.47058823529411764,
              ],
              specular: [
                0.06666666666666667,
                0.06666666666666667,
                0.06666666666666667,
              ],
              shininess: 30,
              emission: [0, 0, 0],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 6647160, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_77_Phong: {
        extensions: {
          KHR_materials_common: {
            technique: "PHONG",
            values: {
              ambient: [
                0.34901960784313724,
                0.34901960784313724,
                0.34901960784313724,
              ],
              diffuse: [
                0.34901960784313724,
                0.34901960784313724,
                0.34901960784313724,
              ],
              specular: [
                0.06666666666666667,
                0.06666666666666667,
                0.06666666666666667,
              ],
              shininess: 30,
              emission: [0, 0, 0],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 11186877, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_78_Phong: {
        extensions: {
          KHR_materials_common: {
            technique: "PHONG",
            values: {
              ambient: [
                0.396078431372549,
                0.42745098039215684,
                0.47058823529411764,
              ],
              diffuse: [
                0.396078431372549,
                0.42745098039215684,
                0.47058823529411764,
              ],
              specular: [
                0.06666666666666667,
                0.06666666666666667,
                0.06666666666666667,
              ],
              shininess: 30,
              emission: [0, 0, 0],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 6647160, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_79_Phong: {
        extensions: {
          KHR_materials_common: {
            technique: "PHONG",
            values: {
              ambient: [
                0.34901960784313724,
                0.34901960784313724,
                0.34901960784313724,
              ],
              diffuse: [
                0.34901960784313724,
                0.34901960784313724,
                0.34901960784313724,
              ],
              specular: [
                0.06666666666666667,
                0.06666666666666667,
                0.06666666666666667,
              ],
              shininess: 30,
              emission: [0, 0, 0],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 11186877, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
    {
      material_80_Phong: {
        extensions: {
          KHR_materials_common: {
            technique: "PHONG",
            values: {
              ambient: [
                0.396078431372549,
                0.42745098039215684,
                0.47058823529411764,
              ],
              diffuse: [
                0.396078431372549,
                0.42745098039215684,
                0.47058823529411764,
              ],
              specular: [
                0.06666666666666667,
                0.06666666666666667,
                0.06666666666666667,
              ],
              shininess: 30,
              emission: [0, 0, 0],
              transparent: true,
              transparency: 1,
            },
          },
        },
        spe_options: {
          side: 0,
          wireframe: false,
          flatShading: false,
          visible: true,
        },
        spe_layers: [
          {
            type: "color",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
              color: { name: "color", value: 6647160, type: 6 },
            },
          },
          {
            type: "light",
            uniforms: {
              alpha: { name: "alpha", value: 1, type: 1 },
              mode: { name: "mode", value: 0, type: 1 },
            },
          },
        ],
      },
    },
  ],
  cameras: [
    {
      type: "Orthographic",
      orthographic: { xmag: 750, ymag: 750, zfar: 100000, znear: -50000 },
      spe_options: { zoom: 1 },
    },
  ],
  buffers: [],
  bufferViews: [],
  accessors: [],
  extensions: {
    KHR_lights_punctual: {
      lights: [
        {
          type: "hemispheric",
          name: "Default Ambient Light",
          color: [0.8274509803921568, 0.8274509803921568, 0.8274509803921568],
          intensity: 1,
        },
        {
          type: "directional",
          name: "Default Directional Light",
          color: [1, 1, 1],
          intensity: 1,
          shadows: {
            castShadow: false,
            shadowmapViewRight: 5,
            shadowmapViewLeft: -5,
            shadowmapViewTop: 5,
            shadowmapViewBottom: -5,
            shadowmapViewNear: 0.5,
            shadowmapViewFar: 500,
          },
        },
      ],
    },
  },
  spline: {
    interactions: [
      { uuid: "584F8ACC-9413-4615-946A-EED30DCF5041" },
      {
        uuid: "C8A4E307-F76F-41D9-922A-65B7B6464735",
        selectedState: 0,
        states: [
          "C9210A67-9622-4B19-A2F6-BB0F0114BC16",
          "3EF45006-ED75-47E7-979C-EC8DC933668F",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "A464A30B-49C3-4ECC-B9D4-41DAF74FF2EA",
                state: "3EF45006-ED75-47E7-979C-EC8DC933668F",
              },
            ],
          },
        ],
      },
      {
        uuid: "4FD848DC-CA78-4291-981A-A3617B2F0D4D",
        selectedState: 0,
        states: [
          "DDB60C42-6325-4FBE-965F-603749B34D2D",
          "62187E2B-E711-4CF2-9F68-EA9606B6F567",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "386F4F02-1C81-41DD-8F4B-CA776AED211F",
                state: "62187E2B-E711-4CF2-9F68-EA9606B6F567",
              },
            ],
          },
        ],
      },
      {
        uuid: "056FE22A-94DA-4CC4-9328-54C740E89923",
        selectedState: 0,
        states: [
          "440168A2-D9B7-4D4A-BF74-C488043BEE40",
          "B6F69D50-FE4C-4C2E-B67F-1EA5495865AE",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "C25C4AC7-1749-47C2-8F0A-AD22DFC3000F",
                state: "B6F69D50-FE4C-4C2E-B67F-1EA5495865AE",
              },
            ],
          },
        ],
      },
      { uuid: "C039D9C8-9575-44C1-84F3-E7EB3CC0F234" },
      {
        uuid: "939E0ABC-53E2-4A86-99D5-E296BEC98ACF",
        selectedState: 0,
        states: [
          "3233CCC0-7909-44B3-8F81-2DDB422CBD49",
          "64C1CE20-4BD1-47CF-AC47-CC192DA52D40",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "5FF6B261-39DA-4577-9F9D-36E603A73110",
                state: "64C1CE20-4BD1-47CF-AC47-CC192DA52D40",
              },
            ],
          },
        ],
      },
      { uuid: "35D623A5-6E2B-4D91-A052-D704B79044C9" },
      {
        uuid: "506DEDD9-E418-4BB0-8C2F-9CCFC88EA41B",
        selectedState: 0,
        states: [
          "7ED5B06B-9BC8-4A3D-AAAB-288367832A0D",
          "09EE418B-ED33-4570-BBDC-C2C5A04F1165",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "7736728E-910D-4A8B-9FC0-B16AF4FB991B",
                state: "09EE418B-ED33-4570-BBDC-C2C5A04F1165",
              },
            ],
          },
        ],
      },
      {
        uuid: "1C21E0B3-53AA-475C-B39B-DB3696CB05A1",
        selectedState: 0,
        states: [
          "3D321489-28ED-48BC-9C08-F51BB52D4C7C",
          "06CF35E4-2938-478C-8B7E-760DA764DCD5",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "F452059F-7D98-4D09-9A22-CFFB67DEFBC6",
                state: "06CF35E4-2938-478C-8B7E-760DA764DCD5",
              },
            ],
          },
        ],
      },
      {
        uuid: "4AD0F4DA-35DE-4022-B798-738343001A83",
        selectedState: 0,
        states: [
          "BD8D59DF-6FAA-4F64-8144-2EFA22A13A0F",
          "5CBF255B-C63A-4937-BADE-785108CF8EE2",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "D372239B-2659-436D-9607-311D230368E2",
                state: "5CBF255B-C63A-4937-BADE-785108CF8EE2",
              },
            ],
          },
        ],
      },
      {
        uuid: "1CFE8FB6-870F-479D-BD5F-F6F409176EED",
        selectedState: 0,
        states: [
          "BC9E1047-EA40-43DF-8C7E-6CDE23606610",
          "786C8DF2-0F9F-4965-B011-D96FBF878BEA",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "4A0422AA-BC7A-48A9-A974-DC57212EF2EF",
                state: "786C8DF2-0F9F-4965-B011-D96FBF878BEA",
              },
            ],
          },
        ],
      },
      { uuid: "33E50FD5-15A7-464D-8FC4-33D0DF645CED" },
      { uuid: "CE7F850F-0252-4CFC-9D44-27DC1D1E7D2E" },
      { uuid: "8ACCA028-63D2-4BBE-9E0F-6D590D5C4DE0" },
      { uuid: "75C49C9A-F3C3-4303-888B-275D3E7AEF41" },
      {
        uuid: "39D2FE31-6942-41CE-9DDB-7D51185CB293",
        selectedState: 0,
        states: [
          "221219D3-6E64-4B77-89E0-2CC19EECA0F9",
          "71B16680-392E-4964-8A27-FE019D60F42B",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "F4EC750A-D531-42F2-BE9E-94E7BD43B4CA",
                state: "71B16680-392E-4964-8A27-FE019D60F42B",
              },
            ],
          },
        ],
      },
      { uuid: "355221A6-8216-46E6-BF64-4E5F7047A701" },
      {
        uuid: "BA6BEE4A-30DA-49E8-94E8-20A886435883",
        selectedState: 0,
        states: [
          "CD6CAC4D-630E-4EFC-956C-67FAE8F0C272",
          "CBCDA16E-7FCD-4FDC-90C0-05DA888995C2",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "8BB81D78-8345-461C-932A-DE2D164B04BA",
                state: "CBCDA16E-7FCD-4FDC-90C0-05DA888995C2",
              },
            ],
          },
        ],
      },
      {
        uuid: "84285BF4-E67A-4F18-8241-650D84E9B0D7",
        selectedState: 0,
        states: [
          "84E3B518-040E-42CD-AB03-DC00FFC558CD",
          "AD04B59A-931C-491E-AAF8-1AF1EFA25597",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "0B1EB067-D829-4FCF-81CF-E029F254379F",
                state: "AD04B59A-931C-491E-AAF8-1AF1EFA25597",
              },
            ],
          },
        ],
      },
      {
        uuid: "44A191DE-9A80-4D15-8F0D-3B6C685AB84D",
        selectedState: 0,
        states: [
          "F8D319DD-5939-4C3D-9FCC-4A8F91EEF8FB",
          "199B9CF9-9E07-4BCD-A8B3-21A01EAA7D62",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "429E475C-BE69-49CF-922C-D9FA65D9DC86",
                state: "199B9CF9-9E07-4BCD-A8B3-21A01EAA7D62",
              },
            ],
          },
        ],
      },
      {
        uuid: "5944BB19-A780-4AC1-9738-66B3A2593DA3",
        selectedState: 0,
        states: [
          "D0D61978-D6C0-41B0-BF8B-8CC8D247BC91",
          "167DE9D2-080F-47C7-A3F7-B1A4881C5A27",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "8B7B328E-9517-4663-A9F2-DEB9AC5CAF8C",
                state: "167DE9D2-080F-47C7-A3F7-B1A4881C5A27",
              },
            ],
          },
        ],
      },
      { uuid: "E9EE8E25-E1AA-45D4-9B77-A33C6D81485F" },
      { uuid: "F02EFBEA-7F9A-442F-B829-3539434982B4" },
      { uuid: "09C5B16B-5B38-4AA8-B653-F57862594F61" },
      { uuid: "EA740E9D-CD6C-4FF7-9614-B841D6957F5D" },
      {
        uuid: "C898929E-CFF6-428A-8282-D175CF934618",
        selectedState: 0,
        states: [
          "2735342F-73D6-4E19-9424-A41C19D8A209",
          "376A970E-7545-4CBD-9066-FC9B159E972C",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "6635A57F-31C5-4CCB-A4DA-958662ACB97C",
                state: "376A970E-7545-4CBD-9066-FC9B159E972C",
              },
            ],
          },
        ],
      },
      { uuid: "A58936C8-7F9B-4B32-A9B2-54AF6BE1C628" },
      {
        uuid: "45B2AB95-67B0-4621-ABFC-52826F454A14",
        selectedState: 0,
        states: [
          "C5EC2D3F-543B-4EAD-8EA3-3D75077AE6DC",
          "F43665D3-C21A-486E-BBA3-44201D6154C0",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "6EB12B5E-15A6-4DA7-9D51-123720965180",
                state: "F43665D3-C21A-486E-BBA3-44201D6154C0",
              },
            ],
          },
        ],
      },
      {
        uuid: "F453FDF0-9DC8-4EFB-A53F-6298BA7903BE",
        selectedState: 0,
        states: [
          "907E0424-ECE5-4309-8A26-D7482B3A6F54",
          "5E2D74B3-1BF2-4AE9-9952-DB1621DF396F",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "2D4EF834-0B74-4877-9910-4E4EFB060444",
                state: "5E2D74B3-1BF2-4AE9-9952-DB1621DF396F",
              },
            ],
          },
        ],
      },
      {
        uuid: "26685BA6-43A2-4860-807E-FCE6064AAF85",
        selectedState: 0,
        states: [
          "EC46DAA7-3C74-4301-8D2D-8740C9818CA5",
          "01104DE4-87A1-4782-BE4A-3B390A6FB53E",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "FBBCE673-D88F-4C09-918A-6252787397E7",
                state: "01104DE4-87A1-4782-BE4A-3B390A6FB53E",
              },
            ],
          },
        ],
      },
      {
        uuid: "A4EA6217-028D-4B6D-99CC-2B56AC14F76D",
        selectedState: 0,
        states: [
          "DF92F24A-9A7A-49D0-929A-3BD3380D8C9F",
          "C296AD2D-8043-4A72-8E75-6CB87545CC70",
        ],
        events: [
          {
            type: 2,
            ui: { isCollapsed: false },
            targets: [
              {
                easing: 6,
                duration: 1000,
                delay: 0,
                cubicControls: [0.5, 0.05, 0.1, 0.3],
                springParameters: {
                  mass: 1,
                  stiffness: 80,
                  damping: 10,
                  velocity: 0,
                },
                repeat: false,
                cycle: false,
                rewind: false,
                object: "A9A3B61E-C183-47E6-8C8A-38BE45174FFB",
                state: "C296AD2D-8043-4A72-8E75-6CB87545CC70",
              },
            ],
          },
        ],
      },
      { uuid: "A8842A37-70A1-48B3-B39B-B01D98FC7EE2" },
      { uuid: "F1691CFA-586C-4886-A1D4-D24CA2905E9B" },
      { uuid: "ADE3EF80-633B-46FB-985F-3D97EE34681B" },
      { uuid: "D3992949-6E1A-42B5-A392-D88E64578A89" },
      { uuid: "C724E25D-11B8-4F60-8378-6C5CAF9116E4" },
      { uuid: "328BC212-FD76-4180-9D56-FFF7EDD84F01" },
      { uuid: "D270E6A0-90BF-432F-9139-87066491719B" },
      { uuid: "899C8699-AD8E-4765-A183-276CA275D5D8" },
      { uuid: "6BB4F0B4-175B-4A38-B9D1-D750ED8A881E" },
      { uuid: "612D2173-1EAA-49C0-A027-76230C570165" },
    ],
    interactionStates: {
      "C9210A67-9622-4B19-A2F6-BB0F0114BC16": {
        uuid: "C9210A67-9622-4B19-A2F6-BB0F0114BC16",
        name: "Base State",
        position: [107.84696913502786, -202.3850229698868, -6.388879651614417],
        rotation: [
          2.4434609527920617,
          0.3490658503988659,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.5312102657947828, 0.4902547654611347, 0.46906745190653515],
        hiddenMatrix: [
          0.6410635155096013,
          0,
          0,
          0,
          0,
          0.6646942800788955,
          0,
          0,
          0,
          0,
          0.6189258312020461,
          0,
          -64.00280336681985,
          40.59486609460611,
          -110.22944498750368,
          1,
        ],
      },
      "3EF45006-ED75-47E7-979C-EC8DC933668F": {
        uuid: "3EF45006-ED75-47E7-979C-EC8DC933668F",
        name: "State 1",
        position: [-81.91409759778591, 39.852198708024595, 48.61298990676298],
        rotation: [0, 0, 0, "XYZ"],
        scale: [1.1503525257583849, 1.1216369295680153, 1.0810461563480906],
        hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      },
      "DDB60C42-6325-4FBE-965F-603749B34D2D": {
        uuid: "DDB60C42-6325-4FBE-965F-603749B34D2D",
        name: "Base State",
        position: [337.34295328320167, 96.58243515423808, -215.16058805616606],
        rotation: [
          -3.141592653589793,
          0.5235987755982987,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.5312102657947828, 0.4902547654611347, 0.4690674519065352],
        hiddenMatrix: [
          0.6410635155096013,
          0,
          0,
          0,
          0,
          0.6646942800788955,
          0,
          0,
          0,
          0,
          0.6189258312020461,
          0,
          -64.00280336681985,
          40.59486609460611,
          -110.22944498750368,
          1,
        ],
      },
      "62187E2B-E711-4CF2-9F68-EA9606B6F567": {
        uuid: "62187E2B-E711-4CF2-9F68-EA9606B6F567",
        name: "State 1",
        position: [-81.91409759778591, 39.852198708024595, 48.61298990676298],
        rotation: [0, 0, 0, "XYZ"],
        scale: [1.1503525257583849, 1.1216369295680153, 1.0810461563480906],
        hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      },
      "440168A2-D9B7-4D4A-BF74-C488043BEE40": {
        uuid: "440168A2-D9B7-4D4A-BF74-C488043BEE40",
        name: "Base State",
        position: [-137.66600453049634, 96.58243515423806, -212.57955019134621],
        rotation: [
          -3.141592653589793,
          -0.5235987755982987,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.5312102657947828, 0.4902547654611347, 0.4690674519065352],
        hiddenMatrix: [
          0.6410635155096013,
          0,
          0,
          0,
          0,
          0.6646942800788955,
          0,
          0,
          0,
          0,
          0.6189258312020461,
          0,
          -64.00280336681985,
          40.59486609460611,
          -110.22944498750368,
          1,
        ],
      },
      "B6F69D50-FE4C-4C2E-B67F-1EA5495865AE": {
        uuid: "B6F69D50-FE4C-4C2E-B67F-1EA5495865AE",
        name: "State 1",
        position: [-81.91409759778591, 39.852198708024595, 48.61298990676298],
        rotation: [0, 0, 0, "XYZ"],
        scale: [1.1503525257583849, 1.1216369295680153, 1.0810461563480906],
        hiddenMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      },
      "3233CCC0-7909-44B3-8F81-2DDB422CBD49": {
        uuid: "3233CCC0-7909-44B3-8F81-2DDB422CBD49",
        name: "Base State",
        position: [-25.86230841963186, -85.75431170642273, 51.53162321895245],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 280, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "64C1CE20-4BD1-47CF-AC47-CC192DA52D40": {
        uuid: "64C1CE20-4BD1-47CF-AC47-CC192DA52D40",
        name: "State 1",
        position: [-25.86230841963186, -85.75431170642273, 51.53162321895245],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 280, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "7ED5B06B-9BC8-4A3D-AAAB-288367832A0D": {
        uuid: "7ED5B06B-9BC8-4A3D-AAAB-288367832A0D",
        name: "Base State",
        position: [49.86319490482285, -13.318956934748499, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "09EE418B-ED33-4570-BBDC-C2C5A04F1165": {
        uuid: "09EE418B-ED33-4570-BBDC-C2C5A04F1165",
        name: "State 1",
        position: [49.86319490482285, -13.318956934748499, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "3D321489-28ED-48BC-9C08-F51BB52D4C7C": {
        uuid: "3D321489-28ED-48BC-9C08-F51BB52D4C7C",
        name: "Base State",
        position: [-116.79542130962759, 63.898475103964785, 55.06093945452554],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "06CF35E4-2938-478C-8B7E-760DA764DCD5": {
        uuid: "06CF35E4-2938-478C-8B7E-760DA764DCD5",
        name: "State 1",
        position: [-116.79542130962759, 63.898475103964785, 55.06093945452554],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "BD8D59DF-6FAA-4F64-8144-2EFA22A13A0F": {
        uuid: "BD8D59DF-6FAA-4F64-8144-2EFA22A13A0F",
        name: "Base State",
        position: [51.625887954988585, 63.898475103964785, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "5CBF255B-C63A-4937-BADE-785108CF8EE2": {
        uuid: "5CBF255B-C63A-4937-BADE-785108CF8EE2",
        name: "State 1",
        position: [51.625887954988585, 63.898475103964785, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "BC9E1047-EA40-43DF-8C7E-6CDE23606610": {
        uuid: "BC9E1047-EA40-43DF-8C7E-6CDE23606610",
        name: "Base State",
        position: [26.629695576078788, 133.50409834719503, 48.69597930493849],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "786C8DF2-0F9F-4965-B011-D96FBF878BEA": {
        uuid: "786C8DF2-0F9F-4965-B011-D96FBF878BEA",
        name: "State 1",
        position: [26.629695576078788, 133.50409834719503, 48.69597930493849],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "221219D3-6E64-4B77-89E0-2CC19EECA0F9": {
        uuid: "221219D3-6E64-4B77-89E0-2CC19EECA0F9",
        name: "Base State",
        position: [-25.86230841963186, -85.75431170642273, 51.53162321895245],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 280, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "71B16680-392E-4964-8A27-FE019D60F42B": {
        uuid: "71B16680-392E-4964-8A27-FE019D60F42B",
        name: "State 1",
        position: [-25.86230841963186, -85.75431170642273, 51.53162321895245],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 280, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "CD6CAC4D-630E-4EFC-956C-67FAE8F0C272": {
        uuid: "CD6CAC4D-630E-4EFC-956C-67FAE8F0C272",
        name: "Base State",
        position: [49.86319490482285, -13.318956934748499, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "CBCDA16E-7FCD-4FDC-90C0-05DA888995C2": {
        uuid: "CBCDA16E-7FCD-4FDC-90C0-05DA888995C2",
        name: "State 1",
        position: [49.86319490482285, -13.318956934748499, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "84E3B518-040E-42CD-AB03-DC00FFC558CD": {
        uuid: "84E3B518-040E-42CD-AB03-DC00FFC558CD",
        name: "Base State",
        position: [-116.79542130962759, 63.898475103964785, 55.06093945452554],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "AD04B59A-931C-491E-AAF8-1AF1EFA25597": {
        uuid: "AD04B59A-931C-491E-AAF8-1AF1EFA25597",
        name: "State 1",
        position: [-116.79542130962759, 63.898475103964785, 55.06093945452554],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "F8D319DD-5939-4C3D-9FCC-4A8F91EEF8FB": {
        uuid: "F8D319DD-5939-4C3D-9FCC-4A8F91EEF8FB",
        name: "Base State",
        position: [51.625887954988585, 63.898475103964785, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "199B9CF9-9E07-4BCD-A8B3-21A01EAA7D62": {
        uuid: "199B9CF9-9E07-4BCD-A8B3-21A01EAA7D62",
        name: "State 1",
        position: [51.625887954988585, 63.898475103964785, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "D0D61978-D6C0-41B0-BF8B-8CC8D247BC91": {
        uuid: "D0D61978-D6C0-41B0-BF8B-8CC8D247BC91",
        name: "Base State",
        position: [26.629695576078788, 133.50409834719503, 48.69597930493849],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "167DE9D2-080F-47C7-A3F7-B1A4881C5A27": {
        uuid: "167DE9D2-080F-47C7-A3F7-B1A4881C5A27",
        name: "State 1",
        position: [26.629695576078788, 133.50409834719503, 48.69597930493849],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "2735342F-73D6-4E19-9424-A41C19D8A209": {
        uuid: "2735342F-73D6-4E19-9424-A41C19D8A209",
        name: "Base State",
        position: [-25.86230841963186, -85.75431170642273, 51.53162321895245],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 280, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "376A970E-7545-4CBD-9066-FC9B159E972C": {
        uuid: "376A970E-7545-4CBD-9066-FC9B159E972C",
        name: "State 1",
        position: [-25.86230841963186, -85.75431170642273, 51.53162321895245],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 280, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "C5EC2D3F-543B-4EAD-8EA3-3D75077AE6DC": {
        uuid: "C5EC2D3F-543B-4EAD-8EA3-3D75077AE6DC",
        name: "Base State",
        position: [49.86319490482285, -13.318956934748499, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "F43665D3-C21A-486E-BBA3-44201D6154C0": {
        uuid: "F43665D3-C21A-486E-BBA3-44201D6154C0",
        name: "State 1",
        position: [49.86319490482285, -13.318956934748499, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "907E0424-ECE5-4309-8A26-D7482B3A6F54": {
        uuid: "907E0424-ECE5-4309-8A26-D7482B3A6F54",
        name: "Base State",
        position: [-116.79542130962759, 63.898475103964785, 55.06093945452554],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "5E2D74B3-1BF2-4AE9-9952-DB1621DF396F": {
        uuid: "5E2D74B3-1BF2-4AE9-9952-DB1621DF396F",
        name: "State 1",
        position: [-116.79542130962759, 63.898475103964785, 55.06093945452554],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "EC46DAA7-3C74-4301-8D2D-8740C9818CA5": {
        uuid: "EC46DAA7-3C74-4301-8D2D-8740C9818CA5",
        name: "Base State",
        position: [51.625887954988585, 63.898475103964785, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "01104DE4-87A1-4782-BE4A-3B390A6FB53E": {
        uuid: "01104DE4-87A1-4782-BE4A-3B390A6FB53E",
        name: "State 1",
        position: [51.625887954988585, 63.898475103964785, 50.17926772173054],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 125, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "DF92F24A-9A7A-49D0-929A-3BD3380D8C9F": {
        uuid: "DF92F24A-9A7A-49D0-929A-3BD3380D8C9F",
        name: "Base State",
        position: [26.629695576078788, 133.50409834719503, 48.69597930493849],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 3685974 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
      "C296AD2D-8043-4A72-8E75-6CB87545CC70": {
        uuid: "C296AD2D-8043-4A72-8E75-6CB87545CC70",
        name: "State 1",
        position: [26.629695576078788, 133.50409834719503, 48.69597930493849],
        rotation: [
          -3.12413936106985,
          -0.0174532925199433,
          -3.141592653589793,
          "XYZ",
        ],
        scale: [0.9999999999999999, -1, -0.9999999999999999],
        hiddenMatrix: [
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          1,
          0,
          53.42469960698412,
          -63.89847510396477,
          -55.06093945452555,
          1,
        ],
        geometry: { width: 175, height: 45, depth: 101 },
        material: {
          layersList: [
            {
              id: 0,
              type: "color",
              defines: {},
              uniforms: {
                f0_alpha: { value: 1 },
                f0_mode: { value: 0 },
                f0_color: { value: 16162427 },
              },
            },
            {
              id: 1,
              type: "light",
              defines: {},
              uniforms: { f1_alpha: { value: 1 }, f1_mode: { value: 0 } },
            },
          ],
        },
      },
    },
    textures: {},
    images: {},
    mainCameraIndex: 5,
    usePrimitives: true,
    bgColor: [0.09803921568627451, 0.10196078431372549, 0.10588235294117647],
    bgAlpha: 1,
    orbitDamped: true,
    orbitTarget: [-3.249e-320, -3.249e-320, 3.249e-320],
    cameraType: "Orthographic",
    cameraRotate: true,
    cameraPan: true,
    cameraZoom: false,
    viewMode: 2,
    viewWidth: 750,
    viewHeight: 750,
    quality: "default",
    useOrbitControls: true,
  },
}

export const SPLINE_ASSETS_LIST = {
  fonts: ["dosis_extralight"],
  images: [],
  models: [],
  animations: [],
}
