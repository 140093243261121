import React from "react"
import styled from "styled-components"

import { Container } from "../global"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      <FooterColumn>
        <span>Address</span>
        <ul>
          <li>Pingstvägen 4, Hägersten</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Sections</span>
        <ul>
          <li>
            <AnchorLink href="#about">About</AnchorLink>
          </li>
          <li>
            <AnchorLink href="#contact">Contact</AnchorLink>
          </li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Email</span>
        <ul>
          <li>
            <a href="mailto:dangha94@gmail.com">
              <span>💌</span> dangha94@gmail.com
            </a>
          </li>
        </ul>
      </FooterColumn>
    </FooterColumnContainer>
    <BrandContainer>
      {/* <Logo>Finance</Logo> */}
      <FooterText>
        Copyright © {new Date().getFullYear()} Daniel Ghandahari
      </FooterText>
    </BrandContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.color.black.light};
  margin: 80px 0 0;
  padding: 20px 0 80px;
`

const FooterText = styled.p`
  color: ${props => props.theme.color.white.dark};
`

const Logo = styled.div`
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${props => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.white.dark};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.white.dark};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;
      a {
        text-decoration: none;
        color: ${props => props.theme.color.white.dark};
      }
    }
  }
`

export default Footer
